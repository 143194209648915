import React, { useEffect, useMemo } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';

import { Provider } from 'react-redux';

import {
  WixCommentsApiProvider,
  CommentsProps,
} from '@wix/comments-ooi-client';

import { AppDataContext } from 'Group/contexts/AppData/AppData';
import { HostContext } from 'Group/contexts/AppSettings/HostContext';
import { IControllerProps } from '../types';

import { createStore } from './store';
import { Widget } from './Widget';

function FeedWidget(props: WidgetProps<IControllerProps & CommentsProps>) {
  const store = useMemo(() => createStore(props.store), []);
  const state = store.getState();

  useEffect(() => {
    store.dispatch({
      type: 'CONTROLLER_UPDATE',
      payload: props.store,
    });
  }, [props.store]);

  const methods = {
    feed: props.feed,
    group: props.group,
  };
  return (
    <Provider store={store}>
      <AppDataContext.Provider
        value={{ instance: state?.application.instance } as any}
      >
        <HostContext.Provider value={props.host}>
          <WixCommentsApiProvider {...(props as any)}>
            <Widget methods={methods} />
          </WixCommentsApiProvider>
        </HostContext.Provider>
      </AppDataContext.Provider>
    </Provider>
  );
}

export default FeedWidget;
