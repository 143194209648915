import {
  FeedItemPermissions,
  FeedPermissions,
  FeedItem,
  FeedItemEntity,
  ItemReactionsSummary,
  UsersReactions,
  UserReaction,
  Users,
} from '@wix/ambassador-feed-v1-feed-item/types';
import { Topic } from '@wix/ambassador-topics-v1-topic/types';

import { IGroupMember } from '../members/types';

export interface IUsers extends Omit<Users, 'userIds'> {
  users?: IGroupMember[];
}

export interface IUserReaction extends Omit<UserReaction, 'userId'> {
  user: IGroupMember;
}

export interface IUsersReactions extends Omit<UsersReactions, 'userReactions'> {
  reactions: IUserReaction[];
}

export interface IReactionsSummary extends ItemReactionsSummary {
  usersReacted: IUsers;
  usersReactions: IUsersReactions;
  total: number;
}

export interface IFeedItem
  extends Omit<FeedItem, 'createdBy' | 'entity' | 'reactions'> {
  entity: IFeedItemEntity;
  createdBy: IGroupMember;
  reactions: IReactionsSummary;
}

export interface IFeedItemPermissions extends FeedItemPermissions {}
export interface IFeedPermissions extends FeedPermissions {}

export interface IFeedItemEntity extends Omit<FeedItemEntity, 'topics'> {
  topics: Topic[];
}

export interface IFeedListResponse {
  items: IFeedItem[];
  permissions: FeedPermissions;
  prevCursor: string;
  nextCursor: string;
  total: number;
}

export enum IFeedItemActivityType {
  DRAFT_JS = 'DRAFTJS',
  GROUP_COVER_CHANGED = 'GROUP_COVER_CHANGED',
  ABOUT_GROUP_CHANGED = 'ABOUT_GROUP_CHANGED',
  USERS_JOINED = 'USERS_JOINED',
}
