import { EntityState } from '@reduxjs/toolkit';

import { IFeedItem, IFeedPermissions } from 'api/feed/types';

export enum EFeedStatus {
  init = 'init',
  loading = ' loading',
  success = 'success',
  error = 'error',
}

export type FeedStateExtras = {
  status: EFeedStatus;
  permissions?: IFeedPermissions;
  groupId?: string;
  total?: number;
  prevCursor?: string;
  nextCursor?: string;
};

export type IFeedState = EntityState<IFeedItem> & FeedStateExtras;

export type IReactParams = {
  groupId: string;
  feedItemId: string;
  code: string;
};

export type IUnreactParams = {
  groupId: string;
  feedItemId: string;
  code: string;
};

export type ISubscribeParams = {
  feedItemId: string;
  groupId: string;
};

export type IUnsubscribeParams = {
  feedItemId: string;
  groupId: string;
};

export type IPinParams = {
  feedItemId: string;
  groupId: string;
};

export type IUnpinParams = {
  feedItemId: string;
  groupId: string;
};

export type IFetchParams = {
  groupId: string;
  cursor?: string;
  limit?: number;
};
