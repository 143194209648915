import { createSelector } from 'reselect';

import { IRootState } from 'FeedWidget/store/types';

export const groupSelector = createSelector(
  (state: IRootState) => state.group,
  (group) => group,
);

export const groupIdSelector = createSelector(
  groupSelector,
  (group) => group.id,
);
