import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { FEED_WIDGET_WRAPPER } from 'FeedWidget/Widget/dataHooks';
import { JoinGroup } from 'FeedWidget/Widget/JoinGroup/JoinGroup';
import { IControllerActions } from 'FeedWidget/types';
import { EmptyState } from 'Group/Widget/EmptyState/EmptyState';

import { Feed } from './Feed';
import { GroupDetails } from './GroupDetails';
import { groupIdSelector } from './selectors';

import { st, classes } from './Widget.st.css';

export const Widget = memo(
  (props: IControllerActions) => {
    const { t } = useTranslation();
    const groupId = useSelector(groupIdSelector);

    const [opened, setOpened] = useState(false);

    if (!groupId) {
      return (
        <EmptyState
          title={t('groups-web.settings.empty.title')}
          content={t('groups-web.settings.empty.description')}
        />
      );
    }

    return (
      <div className={st(classes.root, {})} data-hook={FEED_WIDGET_WRAPPER}>
        <GroupDetails methods={props.methods} />
        <Feed methods={props.methods} onGuard={handleOpen} groupId={groupId} />
        <JoinGroup
          methods={props.methods}
          isOpen={opened}
          onClose={handleClose}
        />
      </div>
    );

    function handleOpen() {
      setOpened(true);
    }

    function handleClose() {
      setOpened(false);
    }
  },
  () => true,
);
