import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ButtonPriority } from 'wix-ui-tpa';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { DetailsComponent } from 'Group/Widget/Details/Details';
import { IControllerActions } from 'FeedWidget/types';
import settingsParams from 'FeedWidget/settingsParams';

import { Button } from 'common/components/Button';
import { useDidUpdate } from 'common/hooks';

import { groupSelector } from '../selectors';

import { classes, st } from './GroupDetails.st.css';
import { ApiTypesV1GroupResponse } from '@wix/social-groups-api/dist/src/types';

export interface GroupDetailsProps extends IControllerActions {}

export const GroupDetails: React.FC<GroupDetailsProps> = (props) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  const group = useSelector(groupSelector);
  const groupId = settings.get(settingsParams.groupId);

  const groupV1 = useMemo(
    () => ({
      details: {
        description: group.description,
        title: group.title,
        membersName: group.memberTitle,
      },
      memberCount: group.membersCount,
      settings: {
        privacyLevel: group.privacyLevel,
      },
    }),
    [group.id],
  );

  useDidUpdate(() => {
    props.methods.group.fetchGroup(groupId);
  }, [groupId]);

  if (!group.id) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      <div className={classes.details}>
        {/* @ts-expect-error */}
        <DetailsComponent group={groupV1} />
      </div>
      <div>
        <Button
          fullWidth={isMobile}
          priority={ButtonPriority.primary}
          onClick={() => props.methods.group.redirectToGroup()}
        >
          {t('groups-web.go-to-group.label')}
        </Button>
      </div>
    </div>
  );
};
