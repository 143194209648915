import { createStore as createReduxStore, combineReducers } from 'redux';

import { createStateReducer } from './reducer';
import { IRootState } from '../../store/types';

export function createStore(initialState: IRootState) {
  return createReduxStore(
    combineReducers({
      application: createStateReducer('application'),
      feed: createStateReducer('feed'),
      group: createStateReducer('group'),
    }),
    initialState,
  );
}
