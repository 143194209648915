import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  IconToggle,
  IconToggleProps,
  IconToggleLabelPlacement,
  Popover,
  Text,
  TextTypography,
} from 'wix-ui-tpa';

import { IGroupMember } from 'api/members/types';

import { classes, st } from './Reaction.st.css';

export interface ReactionProps
  extends Pick<
    IconToggleProps,
    'checked' | 'label' | 'labelPlacement' | 'icon'
  > {
  className?: string;
  label: number | string;
  reactedMembers?: IGroupMember[];
  cta?: boolean;

  onClick?(): void;

  likeButton?: boolean;
}

export const Reaction: React.FC<ReactionProps> = (props) => {
  const { t } = useTranslation();

  const {
    checked,
    label,
    labelPlacement,
    cta,
    icon,
    reactedMembers,
    onClick,
    likeButton,
  } = props;

  const ReactionComponent = (
    <label
      className={classes.label}
      aria-label={
        checked
          ? t('groups-web.a11y.reactions.unreact')
          : t('groups-web.a11y.reactions.react')
      }
    >
      <IconToggle
        checked={checked}
        icon={icon}
        label={label}
        labelPlacement={labelPlacement}
        disabled={false}
        className={st(
          classes.root,
          { checked, cta, likeButton } as any,
          props.className,
        )}
        onChange={onClick}
      />
    </label>
  );

  if (!reactedMembers || !reactedMembers.length) {
    return ReactionComponent;
  }

  return (
    <Popover
      minWidth={200}
      maxWidth={250}
      hideDelay={150}
      placement="top"
      wiredToSiteColors
    >
      <Popover.Element>{ReactionComponent}</Popover.Element>
      <Popover.Content>
        {reactedMembers.map((member) => (
          <Text typography={TextTypography.runningText}>{member.name}</Text>
        ))}
      </Popover.Content>
    </Popover>
  );
};

Reaction.defaultProps = {
  labelPlacement: IconToggleLabelPlacement.END,
  checked: false,
  onClick: () => {},
};
