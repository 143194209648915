import { IGroupMember } from 'api/members/types';
import { IUserReaction } from 'api/feed/types';

export interface EmojiSummary {
  total: number;
  users: IGroupMember[];
  reactionCode: string;
}

export interface EmojiSummaryMap {
  [emojiCode: string]: EmojiSummary;
}

export function getReactionsSummaryMap(
  reactions: IUserReaction[],
): EmojiSummaryMap {
  return reactions.reduce<EmojiSummaryMap>((acc, userReaction) => {
    const reactionCode = userReaction.reaction?.reactionCode as string;

    const reaction = acc[reactionCode] || {
      total: 0,
      users: [],
    };

    return {
      ...acc,
      [reactionCode]: {
        total: reaction.total + 1,
        users: reaction.users.concat([userReaction.user]),
        reactionCode,
      },
    };
  }, {});
}

export function getLikeSummary(
  usersReactionsMap: EmojiSummaryMap,
  reactionCode = '❤️',
): EmojiSummary {
  return (
    usersReactionsMap[reactionCode] || {
      reactionCode,
      total: 0,
      users: [],
    }
  );
}
